import { default as aboutlv6i1FL0CfMeta } from "/opt/build/repo/pages/about.vue?macro=true";
import { default as _91post_93RdUVIEpRXCMeta } from "/opt/build/repo/pages/blog/[post].vue?macro=true";
import { default as indexmFsYN4t0XfMeta } from "/opt/build/repo/pages/blog/index.vue?macro=true";
import { default as contactNfojXvLlAkMeta } from "/opt/build/repo/pages/contact.vue?macro=true";
import { default as enterprise8Pan9Tqy9pMeta } from "/opt/build/repo/pages/enterprise.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as netlifyeM5Qb9StoIMeta } from "/opt/build/repo/pages/integrations/netlify.vue?macro=true";
import { default as pricing2lLggLVj9TMeta } from "/opt/build/repo/pages/pricing.vue?macro=true";
import { default as privacyjCuzG6sq6pMeta } from "/opt/build/repo/pages/privacy.vue?macro=true";
import { default as terms9FLT7o4CI4Meta } from "/opt/build/repo/pages/terms.vue?macro=true";
import { default as waitlistjj3I101rVxMeta } from "/opt/build/repo/pages/waitlist.vue?macro=true";
export default [
  {
    name: aboutlv6i1FL0CfMeta?.name ?? "about",
    path: aboutlv6i1FL0CfMeta?.path ?? "/about",
    meta: aboutlv6i1FL0CfMeta || {},
    alias: aboutlv6i1FL0CfMeta?.alias || [],
    redirect: aboutlv6i1FL0CfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91post_93RdUVIEpRXCMeta?.name ?? "blog-post",
    path: _91post_93RdUVIEpRXCMeta?.path ?? "/blog/:post()",
    meta: _91post_93RdUVIEpRXCMeta || {},
    alias: _91post_93RdUVIEpRXCMeta?.alias || [],
    redirect: _91post_93RdUVIEpRXCMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog",
    path: indexmFsYN4t0XfMeta?.path ?? "/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: contactNfojXvLlAkMeta?.name ?? "contact",
    path: contactNfojXvLlAkMeta?.path ?? "/contact",
    meta: contactNfojXvLlAkMeta || {},
    alias: contactNfojXvLlAkMeta?.alias || [],
    redirect: contactNfojXvLlAkMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: enterprise8Pan9Tqy9pMeta?.name ?? "enterprise",
    path: enterprise8Pan9Tqy9pMeta?.path ?? "/enterprise",
    meta: enterprise8Pan9Tqy9pMeta || {},
    alias: enterprise8Pan9Tqy9pMeta?.alias || [],
    redirect: enterprise8Pan9Tqy9pMeta?.redirect,
    component: () => import("/opt/build/repo/pages/enterprise.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: netlifyeM5Qb9StoIMeta?.name ?? "integrations-netlify",
    path: netlifyeM5Qb9StoIMeta?.path ?? "/integrations/netlify",
    meta: netlifyeM5Qb9StoIMeta || {},
    alias: netlifyeM5Qb9StoIMeta?.alias || [],
    redirect: netlifyeM5Qb9StoIMeta?.redirect,
    component: () => import("/opt/build/repo/pages/integrations/netlify.vue").then(m => m.default || m)
  },
  {
    name: pricing2lLggLVj9TMeta?.name ?? "pricing",
    path: pricing2lLggLVj9TMeta?.path ?? "/pricing",
    meta: pricing2lLggLVj9TMeta || {},
    alias: pricing2lLggLVj9TMeta?.alias || [],
    redirect: pricing2lLggLVj9TMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacyjCuzG6sq6pMeta?.name ?? "privacy",
    path: privacyjCuzG6sq6pMeta?.path ?? "/privacy",
    meta: privacyjCuzG6sq6pMeta || {},
    alias: privacyjCuzG6sq6pMeta?.alias || [],
    redirect: privacyjCuzG6sq6pMeta?.redirect,
    component: () => import("/opt/build/repo/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: terms9FLT7o4CI4Meta?.name ?? "terms",
    path: terms9FLT7o4CI4Meta?.path ?? "/terms",
    meta: terms9FLT7o4CI4Meta || {},
    alias: terms9FLT7o4CI4Meta?.alias || [],
    redirect: terms9FLT7o4CI4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: waitlistjj3I101rVxMeta?.name ?? "waitlist",
    path: waitlistjj3I101rVxMeta?.path ?? "/waitlist",
    meta: waitlistjj3I101rVxMeta || {},
    alias: waitlistjj3I101rVxMeta?.alias || [],
    redirect: waitlistjj3I101rVxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/waitlist.vue").then(m => m.default || m)
  }
]