<script setup lang="ts">
useHead({
  // set page titles for individual pages with useHead({title: ...})
  // if title starts with ! it will be used as-is, otherwise it will be auto-suffixed
  titleTemplate: (titleChunk) => {
    if (titleChunk && titleChunk.startsWith("!")) return titleChunk.substring(1)
    if (titleChunk) return `${titleChunk} - Streamfold`
    return "Streamfold"
  },
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
