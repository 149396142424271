import revive_payload_client_fQ2pENKtfb from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.0_@unocss+reset@_3yrsq6233q4suj7o4eyoytlxd4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_d1zdigLjfa from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.0_@unocss+reset@_3yrsq6233q4suj7o4eyoytlxd4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_a35LRKursp from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.0_@unocss+reset@_3yrsq6233q4suj7o4eyoytlxd4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_vbibWLd7N3 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.0_@unocss+reset@_3yrsq6233q4suj7o4eyoytlxd4/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_hBGv2AUgxt from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.0_@unocss+reset@_3yrsq6233q4suj7o4eyoytlxd4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_e1UV1PFyt0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.0_@unocss+reset@_3yrsq6233q4suj7o4eyoytlxd4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_uqMcqsTnHO from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.0_@unocss+reset@_3yrsq6233q4suj7o4eyoytlxd4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_4s1Mg6gGYE from "/opt/build/repo/node_modules/.pnpm/nuxt-gtag@2.1.0_magicast@0.3.4_rollup@4.18.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import chunk_reload_client_vhkfUlzVyw from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.0_@unocss+reset@_3yrsq6233q4suj7o4eyoytlxd4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import koala_client_WKDdOaEvL9 from "/opt/build/repo/plugins/koala.client.ts";
export default [
  revive_payload_client_fQ2pENKtfb,
  unhead_d1zdigLjfa,
  router_a35LRKursp,
  debug_vbibWLd7N3,
  payload_client_hBGv2AUgxt,
  check_outdated_build_client_e1UV1PFyt0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_uqMcqsTnHO,
  plugin_client_4s1Mg6gGYE,
  chunk_reload_client_vhkfUlzVyw,
  koala_client_WKDdOaEvL9
]